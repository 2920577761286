import {
  Box,
  Button,
  Select as ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { DeviceTypeList } from "@kg-pos/common";
import { BulkUpdateItemsInput, DeviceType, ItemResponse } from "__generated__/graphql";
import { itemCategoryOptions } from "constant/item";
import { InputOption } from "interfaces";
import React, { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import Select from "react-select";

type BulkItemFormProps = {
  submit: (input: BulkUpdateItemsInput) => Promise<void>;
  backToTable: () => void;
  items: ItemResponse[];
};

export const BulkItemForm: React.VFC<BulkItemFormProps> = ({ backToTable, submit, items }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [selectedAccessControlOptions, setSelectedAccessControlOptions] = useState<InputOption[]>(
    []
  );

  const onSubmit = async (input: BulkUpdateItemsInput): Promise<void> => {
    // 未入力項目のプロパティを消す
    if (Number.isNaN(input.price)) {
      delete input.price;
    }
    if (Number.isNaN(input.sortNo)) {
      delete input.sortNo;
    }
    if (Number.isNaN(input.bottleBack)) {
      delete input.bottleBack;
    }
    // 未入力だと空文字が来る
    if (!input.category) {
      delete input.category;
    }
    // 未入力(一度も操作してない)だとundefinedが来る
    // 操作してから消すと空配列が来る(これで制限なしに戻せる)
    if (!input.accessControl) {
      delete input.accessControl;
    }
    // console.log(input);
    await submit(input);
    toast({
      title: "一括編集が完了しました。",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  // 選ばれた一括選択商品に、ホストと紐付く場内指名があるか
  const isInsideCallForHost = items.some((item) => !!item.host);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Text mb={1}>編集対象商品</Text>
        <Box>
          {items.length ? (
            items.map((item: ItemResponse) => <Text key={item.id}>{`- ${item.name}`}</Text>)
          ) : (
            <Text>なし</Text>
          )}
        </Box>
      </Box>
      <FormControl id="category" isInvalid={!!errors.category} mt={6} maxW="400px">
        <FormLabel>カテゴリー</FormLabel>
        <ChakraSelect
          placeholder="カテゴリーを変更する時だけ選択してください。"
          {...register("category")}
          disabled={isInsideCallForHost}
        >
          {itemCategoryOptions.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </ChakraSelect>
        <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.price} mt={6}>
        <FormLabel htmlFor="price">価格</FormLabel>
        <Input
          id="price"
          type="number"
          placeholder="価格を変更する時だけ入力してください。"
          maxW="400px"
          {...register("price", {
            valueAsNumber: true,
            min: {
              value: -50_000_000,
              message: "-5000万円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="sortNo" mt={6} maxW="400px" isInvalid={!!errors.sortNo}>
        <FormLabel>表示優先度</FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を変更する時だけ入力してください。"
          {...register("sortNo", {
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.bottleBack} mt={6}>
        <FormLabel htmlFor="bottleBack">ボトルバック</FormLabel>
        <Input
          id="bottleBack"
          type="number"
          placeholder="ボトルバックを変更する時だけ入力してください。"
          maxW="400px"
          {...register("bottleBack", {
            valueAsNumber: true,
            min: {
              value: -50_000_000,
              message: "-5000万円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.bottleBack?.message}</FormErrorMessage>
      </FormControl>
      <FormControl mt={6} isInvalid={!!errors.accessControl}>
        <FormLabel>アクセス制限</FormLabel>
        <Select
          {...register("accessControl")}
          placeholder="アクセス制限する端末タイプがあれば入力"
          options={[
            { label: DeviceTypeList[DeviceType.HALL], value: DeviceType.HALL },
            { label: DeviceTypeList[DeviceType.CASHIER], value: DeviceType.CASHIER },
            { label: DeviceTypeList[DeviceType.VIEWER], value: DeviceType.VIEWER },
            { label: DeviceTypeList[DeviceType.KITCHEN], value: DeviceType.KITCHEN },
            { label: DeviceTypeList[DeviceType.OFFICE_STAFF], value: DeviceType.OFFICE_STAFF },
          ]}
          value={selectedAccessControlOptions}
          onChange={(val) => {
            // MultiSelectの状態更新
            setSelectedAccessControlOptions(val as InputOption[]);
            // formの持つ値の更新
            setValue(
              "accessControl",
              (val as InputOption[]).map((v) => v.value as DeviceType)
            );
          }}
          isMulti
          // see: https://react-select.com/styles
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
              height: "40px",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
            }),
          }}
        />
        <FormErrorMessage>{(errors.accessControl as FieldError)?.message}</FormErrorMessage>
      </FormControl>

      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        確定する
      </Button>
    </form>
  );
};
