import { Box, Button } from "@chakra-ui/react";
import { StoreResponse, useGetStoresQuery } from "__generated__/graphql";
import { getStoreRecord } from "common/object/store";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { MainTable } from "components/molecules/main-table";
import { PageTitle } from "components/organisms/page-title";
import { AuthContext } from "contexts/current-user.context";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useTitle } from "hooks/useTitle";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "店舗名",
    accessor: "name",
  },
  {
    Header: "説明",
    accessor: "description",
  },
  {
    Header: "サービス料",
    accessor: "serviceFee",
  },
  {
    Header: "注文ロック時間",
    accessor: "orderItemLockTime",
    Cell: (props: any) => (
      <div>{`${props.value.substring(0, 2)}:${props.value.substring(2, 4)}`}</div>
    ),
  },
];

export const StoresPage: React.VFC = () => {
  const history = useHistory();
  useTitle("店舗");
  const { setTabIndex } = useContext(AuthContext);
  const { loading, error, data } = useGetStoresQuery({
    fetchPolicy: "cache-and-network",
  });
  const { logout, currentUser } = useCurrentUser();

  if (!currentUser) return null;
  if (!setTabIndex) return null;

  const { isMaster, isCollector } = currentUser;

  const handleClick = (row: StoreResponse): void => {
    setTabIndex(0);
    history.push(`${routeConstants.stores}/${row.id}`);
  };

  const handleRegister = (): void => history.push(`${routeConstants.stores}/new`);

  // todo
  const stores = ((data?.stores as StoreResponse[]) ?? []).map(getStoreRecord) as any;

  if (loading) return <LoadingLayout />;
  if (error) {
    // 認証エラーが返ったら強制ログアウト
    if (error.message === "invalid admin") {
      logout();
    }

    return <ErrorLayout />;
  }

  return (
    <>
      <PageTitle title="店舗" breadCrumbItems={[{ label: "店舗", path: "/", active: true }]} />
      <MainCardContainer>
        {isMaster && (
          <Button colorScheme="blue" onClick={handleRegister}>
            店舗を登録する
          </Button>
        )}

        <Box pt="8">
          {/* 集計担当者は店舗編集に遷移させない */}
          <MainTable data={stores} columns={columns} {...(isCollector ? {} : { handleClick })} />
        </Box>
      </MainCardContainer>
    </>
  );
};
