import { useDeleteDeviceMutation, useGetDeviceByIdQuery } from "__generated__/graphql";
import { getDeviceRecord } from "common/object/device";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const DevicePage: React.VFC = () => {
  const id = useParams();
  useTitle("端末詳細");
  const { loading, error, data } = useGetDeviceByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteDevice] = useDeleteDeviceMutation();

  if (loading) return <LoadingLayout />;
  if (error || !data?.device) return <ErrorLayout />;

  const device = getDeviceRecord(data.device);

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteDevice({ variables: { id } })).data?.deleteDevice;
  };

  return (
    <>
      <PageTitle
        title="端末詳細"
        breadCrumbItems={[
          { label: "端末", path: `${routeConstants.stores}/${device.storeId}` },
          {
            label: "端末詳細",
            path: `${routeConstants.devices}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名前", value: device.name },
          { label: "説明", value: device.description ?? "" },
          { label: "端末タイプ", value: device.type },
          { label: "アカウント", value: device.account },
          { label: "パスワード", value: "*********" },
        ]}
        editPath={`${routeConstants.devices}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${device.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
