import { Box } from "@chakra-ui/react";
import {
  UpdateAdminInput,
  useGetAdminByIdQuery,
  useUpdateAdminMutation,
} from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { AdminForm } from "components/organisms/admin-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const AdminEditPage: React.VFC = () => {
  const { goBack } = useHistory();
  const id = useParams();
  useTitle("管理者編集");
  const { loading, error, data } = useGetAdminByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateAdmin] = useUpdateAdminMutation();

  const submit = async (input: UpdateAdminInput) => {
    await updateAdmin({ variables: { ...input } });
  };

  const backToTable = (): void => goBack();

  const admin = data?.adminById;

  if (loading) return <LoadingLayout />;
  if (error || !admin) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="管理者編集"
        breadCrumbItems={[
          { label: "管理者", path: `${routeConstants.stores}/${admin.storeId}` },
          {
            label: "管理者詳細",
            path: `${routeConstants.admins}/${id}`,
          },
          {
            label: "管理者編集",
            path: `${routeConstants.admins}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <AdminForm submit={submit} data={admin} backToTable={backToTable} />
        </Box>
      </MainCardContainer>
    </>
  );
};
