import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { RequireField } from "components/atoms/require-field";
import { InformationCenterSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type InformationCenterFormProps = {
  backToTable: () => void;
  submit: (input: InformationCenterSubmit) => Promise<void>;
  data?: { id: string; name: string; sortNo: number };
};

export const InformationCenterForm: React.VFC<InformationCenterFormProps> = ({
  backToTable,
  submit,
  data,
}) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: data?.name, sortNo: data?.sortNo },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: InformationCenterSubmit): Promise<void> => {
    await submit(input);
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          名称
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="名称を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "名称が必要です。",
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.sortNo} mt={6}>
        <FormLabel htmlFor="sortNo">
          表示優先度
          <RequireField />
        </FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を入力してください。"
          maxW="400px"
          defaultValue={100}
          {...register("sortNo", {
            required: "表示優先度が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
