import ja from "date-fns/locale/ja"; // the locale you want
import React, { HTMLAttributes } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

registerLocale("ja", ja); // register it with the name you want

interface DatePickerProps {
  isClearable?: boolean;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
}

export const DatePicker: React.VFC<DatePickerProps & HTMLAttributes<HTMLElement>> = ({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
}) => {
  // const isLight = useColorMode().colorMode === "light"; //you can check what theme you are using right now however you want

  return (
    <ReactDatePicker
      locale="ja"
      dateFormat="yyyy/MM/dd"
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
      // {...props}
    />
  );
};
