import { InputItemCategory } from "@kg-pos/common";
import { ItemResponse } from "__generated__/graphql";

export const getItemRecord = (item: ItemResponse | undefined) => {
  if (!item) return item;

  const { category } = item;

  return {
    ...item,
    category: InputItemCategory[category as keyof typeof InputItemCategory],
    selectHost: false,
  };
};
