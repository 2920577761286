import { CurrentUser } from "interfaces";
import { createContext, Dispatch, SetStateAction } from "react";

type AuthContextType = {
  currentUser: CurrentUser | null;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>> | null;
};

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  tabIndex: 0,
  setTabIndex: null,
});
