import { useDeleteAdminMutation, useGetAdminByIdQuery } from "__generated__/graphql";
import { getAdminRecord } from "common/object/admin";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const AdminPage: React.VFC = () => {
  const id = useParams();
  useTitle("管理者詳細");
  const { loading, error, data } = useGetAdminByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteAdmin] = useDeleteAdminMutation();

  if (loading) return <LoadingLayout />;
  if (error || !data?.adminById) return <ErrorLayout />;

  const admin = getAdminRecord(data.adminById);

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteAdmin({ variables: { id } })).data?.deleteAdmin;
  };

  return (
    <>
      <PageTitle
        title="管理者詳細"
        breadCrumbItems={[
          { label: "管理者", path: `${routeConstants.stores}/${admin.storeId}` },
          {
            label: "管理者詳細",
            path: `${routeConstants.admins}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "アカウント", value: admin.account },
          { label: "パスワード", value: "********" },
          { label: "管理者タイプ", value: admin.type },
        ]}
        editPath={`${routeConstants.admins}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${admin.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
