import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { StoreResponse } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { AdminLayout } from "components/layouts/tabs/admin-layout";
import { CustomerLayout } from "components/layouts/tabs/customer-layout";
import { DeviceLayout } from "components/layouts/tabs/device-layout";
import { HostLayout } from "components/layouts/tabs/host-layout";
import { InformationCenterLayout } from "components/layouts/tabs/information-center-layout";
import { InitialLayout } from "components/layouts/tabs/initial-layout";
import { ItemLayout } from "components/layouts/tabs/item-layout";
import { PenaltyLayout } from "components/layouts/tabs/penalty-layout";
import { StoreLayout } from "components/layouts/tabs/store-layout";
import { StoreTableLayout } from "components/layouts/tabs/store-table-layout";
import { AuthContext } from "contexts/current-user.context";
import React, { useContext } from "react";

export const StoreInfo: React.VFC<{ store: StoreResponse }> = ({ store }) => {
  const { currentUser, tabIndex, setTabIndex } = useContext(AuthContext);

  if (!currentUser) return null;
  if (!setTabIndex) return null;
  const { isMaster } = currentUser;

  return (
    <Box>
      <MainCardContainer>
        <Tabs isFitted isLazy variant="enclosed" defaultIndex={tabIndex}>
          <TabList mb="1em">
            <Tab onClick={() => setTabIndex(0)}>店舗詳細</Tab>
            <Tab onClick={() => setTabIndex(1)}>ホスト</Tab>
            <Tab onClick={() => setTabIndex(2)}>初回</Tab>
            <Tab onClick={() => setTabIndex(3)}>顧客</Tab>
            <Tab onClick={() => setTabIndex(4)}>商品</Tab>
            <Tab onClick={() => setTabIndex(5)}>罰金</Tab>
            <Tab onClick={() => setTabIndex(6)}>案内所</Tab>
            <Tab onClick={() => setTabIndex(7)}>テーブル</Tab>
            <Tab onClick={() => setTabIndex(8)}>端末</Tab>
            {isMaster && <Tab onClick={() => setTabIndex(9)}>管理者</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel>
              <StoreLayout store={store} />
            </TabPanel>
            <TabPanel>
              <HostLayout />
            </TabPanel>
            <TabPanel>
              <InitialLayout />
            </TabPanel>
            <TabPanel>
              <CustomerLayout />
            </TabPanel>
            <TabPanel>
              <ItemLayout />
            </TabPanel>
            <TabPanel>
              <PenaltyLayout />
            </TabPanel>
            <TabPanel>
              <InformationCenterLayout />
            </TabPanel>
            <TabPanel>
              <StoreTableLayout />
            </TabPanel>
            <TabPanel>
              <DeviceLayout />
            </TabPanel>
            {isMaster && (
              <TabPanel>
                <AdminLayout />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </MainCardContainer>
    </Box>
  );
};
