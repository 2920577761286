import { Box } from "@chakra-ui/react";
import { useGetStoreTableByIdQuery, useUpdateStoreTableMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { PageTitle } from "components/organisms/page-title";
import { StoreTableForm } from "components/organisms/store-table-form";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { StoreTableSubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const StoreTableEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("テーブル編集");
  const { loading, error, data } = useGetStoreTableByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateStoreTable] = useUpdateStoreTableMutation();

  const storeTable = data?.storeTable;

  if (loading) return <LoadingLayout />;
  if (error || !storeTable) return <ErrorLayout />;

  const submit = async (input: StoreTableSubmit): Promise<void> => {
    await updateStoreTable({ variables: { id, ...input } });
  };

  // TODO:
  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="テーブル編集"
        breadCrumbItems={[
          { label: "テーブル", path: `${routeConstants.stores}/${storeTable.storeId}` },
          {
            label: "テーブル詳細",
            path: `${routeConstants.storeTables}/${id}`,
          },
          {
            label: "テーブル編集",
            path: `${routeConstants.storeTables}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <StoreTableForm backToTable={backToTable} submit={submit} data={storeTable} />
        </Box>
      </MainCardContainer>
    </>
  );
};
