import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AuthContext } from "contexts/current-user.context";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CurrentUser } from "interfaces";
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";
import { MobileToggle } from "./mobile-toggle";

type NavbarProps = {
  handleToggleSidebar: (value: boolean) => void;
};

const noNeedVisible = ["/expenses", "/titles"];

export const Navbar: React.VFC<NavbarProps> = ({ handleToggleSidebar }) => {
  const { goBack, location, push } = useHistory();
  const { logout } = useCurrentUser();
  const currentUser = useContext(AuthContext).currentUser as CurrentUser;
  const { adminName } = currentUser;

  const isVisibleArrawBack = useMemo((): boolean => {
    if (noNeedVisible.includes(location.pathname)) return false;

    return location.pathname !== "/";
  }, [location]);

  return (
    <Box d="flex" bg={useColorModeValue("white", "gray.800")} px={4} alignItems="center">
      <MobileToggle handleToggleSidebar={handleToggleSidebar} />
      {isVisibleArrawBack && (
        <button type="button" className="arrow-back" onClick={() => goBack()}>
          <ArrowBackIcon />
        </button>
      )}
      <Flex
        h={16}
        marginLeft="auto"
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection="row"
      >
        <Flex alignItems={"center"} className="navbar-menu">
          <Menu>
            <MenuButton as={Button} rounded={"full"} variant={"link"} cursor={"pointer"}>
              <Flex alignItems="center">
                <Avatar size={"sm"} />
                <Text fontSize="sm" pl="2">
                  {adminName}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => push(routeConstants.setting)}>アカウント設定</MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>ログアウト</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
};
