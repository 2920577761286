import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAdminLoginMutation } from "__generated__/graphql";
import { useTitle } from "hooks/useTitle";
import { CurrentUser, LoginSubmit } from "interfaces";
import { useForm } from "react-hook-form";

type LoginPageProps = {
  setCurrentUser: (currentUser: CurrentUser, save: boolean) => void;
};

export const LoginPage: React.VFC<LoginPageProps> = ({ setCurrentUser }) => {
  // const { toggleColorMode } = useColorMode();
  useTitle("ログイン");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [login, { loading, error }] = useAdminLoginMutation();

  const onSubmit = async (input: LoginSubmit): Promise<void> => {
    const { save, ...rest } = input;
    try {
      const res = await login({ variables: rest });
      const { data } = res;
      if (!data) {
        throw new Error("invalid admin login respnose");
      }

      setCurrentUser(data.adminLogin, save);
      // そのままだとログイン直後に更新後のcurrentUserの値を取得してくれないのでひとまず再読み込み
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6} w="80%">
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>KG POS管理</Heading>
        </Stack>
        {/* <Button onClick={toggleColorMode}>Toggle</Button> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              {error && (
                <Alert status="error">
                  <AlertIcon />
                  ログインできませんでした。
                </Alert>
              )}
              <FormControl id="account" isInvalid={!!errors.account}>
                <FormLabel>アカウント</FormLabel>
                <Input
                  type="account"
                  placeholder="アカウント名を入力してください"
                  {...register("account", {
                    required: "アカウントが必要です。",
                    maxLength: {
                      value: 30,
                      message: "30文字以内で入力してください。",
                    },
                  })}
                />
                <FormErrorMessage>{errors.account && errors.account.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={!!errors.password}>
                <FormLabel>パスワード</FormLabel>
                <Input
                  type="password"
                  placeholder="パスワードを入力してください"
                  {...register("password", {
                    required: "パスワードが必要です。",
                    maxLength: {
                      value: 30,
                      message: "30文字以内で入力してください。",
                    },
                  })}
                />
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox {...register("save")}>ログイン状態を保存する。</Checkbox>
                </Stack>
                <Button
                  type="submit"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  disabled={loading}
                >
                  ログイン
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
};
