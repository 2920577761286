import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { InitialResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { InitialSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type InitialFormProps = {
  backToTable: () => void;
  submit: (input: InitialSubmit) => Promise<void>;
  data?: InitialResponse;
};

export const InitialForm: React.VFC<InitialFormProps> = ({ backToTable, submit, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: data?.name, price: data?.price, description: data?.description },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: InitialSubmit): Promise<void> => {
    await submit(input);
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          名称
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="名称を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "名称が必要です。",
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.price} mt={6}>
        <FormLabel htmlFor="price">
          金額
          <RequireField />
        </FormLabel>
        <Input
          id="price"
          type="number"
          placeholder="価格を入力してください。"
          maxW="400px"
          {...register("price", {
            required: "価格が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.description} mt={6}>
        <FormLabel htmlFor="description">説明</FormLabel>
        <Input
          id="description"
          placeholder="説明を入力してください。"
          maxW="400px"
          {...register("description", {})}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
