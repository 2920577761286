import { getIdForDisplay } from "@kg-pos/common";
import { useDeleteCustomerMutation, useGetCustomerByIdQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const CustomerPage: React.VFC = () => {
  const id = useParams();
  useTitle("顧客詳細");
  const { loading, error, data } = useGetCustomerByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteCustomer] = useDeleteCustomerMutation();
  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteCustomer({ variables: { id } })).data?.deleteCustomer;
  };

  const customer = data?.customerById;

  if (loading) return <LoadingLayout />;
  if (error || !customer || !customer.storeId) {
    console.error(error?.message);

    return <ErrorLayout />;
  }

  return (
    <>
      <PageTitle
        title="顧客詳細"
        breadCrumbItems={[
          { label: "顧客", path: `${routeConstants.stores}/${customer.storeId}` },
          {
            label: "顧客詳細",
            path: `${routeConstants.customers}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "顧客ID", value: getIdForDisplay(customer.id) },
          { label: "名前", value: customer.name },
          { label: "担当", value: customer.hostName },
        ]}
        editPath={`${routeConstants.customers}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${customer.storeId}`}
        handleDelete={handleDelete}
        name={`${getIdForDisplay(customer.id)} ${customer.name}`}
      />
    </>
  );
};
