import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { ExpenseItemResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { ExpenseSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

type ExpenseFormProps = {
  submit: (input: ExpenseSubmit) => Promise<void>;
  path: string;
  data?: ExpenseItemResponse;
};

export const ExpenseForm: React.VFC<ExpenseFormProps> = ({ submit, path, data }) => {
  const history = useHistory();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: data?.name, examples: data?.examples, sortNo: data?.sortNo },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: ExpenseSubmit): Promise<void> => {
    await submit(input);
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    history.push(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          項目名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="項目名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "項目名が必要です。",
            maxLength: { value: 100, message: "100文字以内で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.examples} mt={6}>
        <FormLabel htmlFor="name">経費例</FormLabel>
        <Input
          id="name"
          placeholder="水道代、電気代、ガス代"
          maxW="400px"
          {...register("examples", {
            maxLength: { value: 500, message: "500文字以内で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.examples && errors.examples.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="sortNo" mt={6} maxW="400px" isInvalid={!!errors.sortNo}>
        <FormLabel>
          表示優先度
          <RequireField />
        </FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を入力してください。"
          defaultValue={100}
          {...register("sortNo", {
            required: "表示優先度が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
