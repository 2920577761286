import { StoreResponse } from "__generated__/graphql";

export const getStoreRecord = (store: Omit<StoreResponse, "icon">) => {
  const { serviceFee } = store;

  return {
    ...store,
    serviceFee: `${serviceFee}%`,
  };
};
