import { ApolloProvider } from "@apollo/client";
import { MainContainer } from "components/containers/main";
import { AuthContext } from "contexts/current-user.context";
import { useAppApolloClient } from "core/apollo";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CurrentUser } from "interfaces";
import { LoginPage } from "pages/login";
import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { routes } from "routes";

type RootProps = {
  currentUser: CurrentUser | null;
  setCurrentUser: (currentUser: CurrentUser, save: boolean) => void;
};

const Root: React.VFC<RootProps> = ({ currentUser, setCurrentUser }) => {
  if (!currentUser) {
    return <LoginPage setCurrentUser={setCurrentUser} />;
  }

  return (
    <Router>
      <Switch>
        <MainContainer>
          <Switch>
            {routes.map(({ exact, path, component: Component }) => (
              <Route key={path} exact={exact} path={path}>
                <Component />
              </Route>
            ))}
          </Switch>
        </MainContainer>
      </Switch>
    </Router>
  );
};

export const App: React.VFC = () => {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const client = useAppApolloClient(currentUser?.token ?? null);
  // タブ位置を覚えておく
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={{ currentUser, tabIndex, setTabIndex }}>
        <Root currentUser={currentUser} setCurrentUser={setCurrentUser} />
      </AuthContext.Provider>
    </ApolloProvider>
  );
};
