import { Box } from "@chakra-ui/react";
import { useCreateTitleMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { PageTitle } from "components/organisms/page-title";
import { TitleForm } from "components/organisms/title-form";
import { useTitle } from "hooks/useTitle";
import { TitleSubmit } from "interfaces";
import React from "react";
import { routeConstants } from "routes";

export const TitleNewPage: React.VFC = () => {
  useTitle("役職登録");
  const [createTitle] = useCreateTitleMutation();
  const submit = async (input: TitleSubmit): Promise<void> => {
    await createTitle({ variables: input });
  };

  return (
    <>
      <PageTitle
        title="役職登録"
        breadCrumbItems={[
          { label: "役職", path: routeConstants.titles },
          { label: "役職登録", path: `${routeConstants.titles}/new`, active: true },
        ]}
      />

      <MainCardContainer>
        <Box>
          <TitleForm submit={submit} path={routeConstants.titles} />
        </Box>
      </MainCardContainer>
    </>
  );
};
