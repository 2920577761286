import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { DeviceTypeList } from "@kg-pos/common";
import { DeviceResponse, DeviceType } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { DeviceSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type DeviceFormProps = {
  submit: (input: DeviceSubmit) => Promise<void>;
  backToTable: any;
  data?: DeviceResponse;
};

export const DeviceForm: React.VFC<DeviceFormProps> = ({ submit, backToTable, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: data?.name,
      description: data?.description,
      type: data?.type,
      account: data?.account,
      password: "",
    },
  });

  const isEdit = useMemo(() => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: DeviceSubmit) => {
    try {
      await submit(input);
      toast({
        title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      backToTable();
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(`cannot ${isEdit ? "update" : "create"} device`, error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          端末名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="端末名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "端末名が必要です。",
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.description} mt={6}>
        <FormLabel htmlFor="description">説明</FormLabel>
        <Input
          id="remarks"
          placeholder="説明を入力してください。"
          maxW="400px"
          {...register("description", {
            maxLength: { value: 500, message: "500文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.type} mt={6}>
        <FormLabel htmlFor="type">
          端末タイプ
          <RequireField />
        </FormLabel>
        <Select
          placeholder="端末タイプを選択してください。"
          {...register("type", {
            required: "端末タイプを選択してください。",
          })}
          maxW="400px"
          disabled={!!data}
        >
          <option value={DeviceType.CASHIER}>{DeviceTypeList[DeviceType.CASHIER]}</option>
          <option value={DeviceType.HALL}>{DeviceTypeList[DeviceType.HALL]}</option>
          <option value={DeviceType.VIEWER}>{DeviceTypeList[DeviceType.VIEWER]}</option>
          <option value={DeviceType.KITCHEN}>{DeviceTypeList[DeviceType.KITCHEN]}</option>
          <option value={DeviceType.OFFICE_STAFF}>{DeviceTypeList[DeviceType.OFFICE_STAFF]}</option>
        </Select>
        <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.account} mt={6}>
        <FormLabel htmlFor="account">
          端末ログイン用アカウント
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="端末ログイン用アカウントを入力してください。"
          maxW="400px"
          {...register("account", {
            required: "端末ログイン用アカウントが必要です。",
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.account?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.password} mt={6}>
        <FormLabel htmlFor="password">
          端末ログイン用パスワード
          {!isEdit && <RequireField />}
        </FormLabel>
        <Input
          id="password"
          type="password"
          placeholder={
            isEdit
              ? "変更する時だけ入力してください。"
              : "端末ログイン用パスワードを入力してください。"
          }
          maxW="400px"
          {...register("password", {
            minLength: { value: 8, message: "8文字以上で入力してください。" },
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
            // 作成時のみ必須
            ...(!isEdit && { required: "端末ログイン用パスワードが必要です。" }),
          })}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
