import { useGetStoreQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { PageTitle } from "components/organisms/page-title";
import { StoreInfo } from "components/organisms/store-info";
import { AuthContext } from "contexts/current-user.context";
import { StoreContext } from "contexts/store.context";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React, { useContext } from "react";
import { routeConstants } from "routes";

export const StorePage: React.VFC = () => {
  const { setTabIndex } = useContext(AuthContext);
  const id = useParams();
  useTitle("店舗詳細");
  const { loading, error, data } = useGetStoreQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const store = data?.store;

  if (loading) return null;
  if (error || !store) return <ErrorLayout />;

  return (
    <StoreContext.Provider value={{ storeId: id }}>
      <PageTitle
        title={store.name}
        breadCrumbItems={[
          {
            label: "店舗",
            path: `${routeConstants.stores}/${id}`,
            onClick: () => {
              // 店舗詳細タブに戻る
              setTabIndex && setTabIndex(0);
            },
          },
          {
            label: store.name,
            path: `${routeConstants.stores}/${id}`,
            active: true,
          },
        ]}
      />
      <StoreInfo store={store} />
    </StoreContext.Provider>
  );
};
