import { ArrowBackIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, Button, Stack, Text } from "@chakra-ui/react";
import { UNIQUE_VALIDATION } from "@kg-pos/common";
import {
  DeviceResponse,
  useCreateDeviceMutation,
  useGetDevicesByStoreIdQuery,
} from "__generated__/graphql";
import { getDeviceRecord } from "common/object/device";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { DeviceForm } from "components/organisms/device-form";
import { StoreContext } from "contexts/store.context";
import { DeviceSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "端末名",
    accessor: "name",
  },
  {
    Header: "説明",
    accessor: "description",
  },
  {
    Header: "端末タイプ",
    accessor: "type",
  },
  {
    Header: "ログイン用アカウント",
    accessor: "account",
  },
];

export const DeviceLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetDevicesByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createDevice, { error: createDeviceError }] = useCreateDeviceMutation();

  const backToTable = async (): Promise<void> => {
    setForm(false);

    await refetch();
  };

  const handleClick = (row: DeviceResponse): void => {
    push(`${routeConstants.devices}/${row.id}?storeId=${storeId}`);
  };

  const submit = async (input: DeviceSubmit): Promise<void> => {
    await createDevice({
      variables: { storeId, ...input },
    });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          {createDeviceError && (
            <>
              <Text fontSize="sm" color="red.500" mb={2}>
                端末登録に失敗しました。
                {createDeviceError?.message.includes(UNIQUE_VALIDATION) &&
                  "同一アカウント名の端末が存在します。"}
              </Text>
            </>
          )}
          <DeviceForm submit={submit} backToTable={backToTable} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            端末を登録する
          </Button>
          <Stack spacing={3} mb={3}>
            <Alert status="warning">
              <AlertIcon />
              POS端末にログインするためのアカウントを登録します。
            </Alert>
          </Stack>
          <MainTable
            data={(data?.devicesByStoreId ?? []).map(getDeviceRecord)}
            columns={columns}
            handleClick={handleClick}
          />
        </>
      )}
    </>
  );
};
