import { Box, useColorModeValue } from "@chakra-ui/react";
import { AsideLayout } from "components/layouts/aside";
import { Navbar } from "components/organisms/navbar";
import React, { useState } from "react";

export const MainContainer: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [toggled, setToggled] = useState<boolean>(false);
  const handleToggleSidebar = (value: boolean): void => setToggled(value);
  const handleCollapsedChange = (checked: boolean): void => setCollapsed(checked);

  return (
    <div className={`main-container ${toggled ? "toggled" : ""}`}>
      <AsideLayout
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <Box
        w="full"
        h="full"
        flexGrow={1}
        className="overflow-y-scroll"
        bg={useColorModeValue("gray.100", "inherit")}
      >
        <Navbar handleToggleSidebar={handleToggleSidebar} />
        <Box>
          <Box className="section-container" as="section" pb="12" px={{ md: "6" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
