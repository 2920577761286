import { Box, Flex } from "@chakra-ui/react";
import { Logo } from "components/atoms/logo";
import { AuthContext } from "contexts/current-user.context";
import React, { useContext } from "react";
import { FaCoins, FaStore, FaUserTie } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { routeConstants } from "routes";

type AsideLayoutProps = {
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar: (value: boolean) => void;
  handleCollapsedChange: (checked: boolean) => void;
};

export const AsideLayout: React.VFC<AsideLayoutProps> = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const currentUser = useContext(AuthContext).currentUser;
  if (!currentUser) return null;
  const { isMaster } = currentUser;

  return (
    <ProSidebar
      image={undefined}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <Flex p={6} alignItems="center">
          <Logo />
        </Flex>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaStore />}>
            店舗
            <Link to="/" />
          </MenuItem>
          {isMaster && (
            <MenuItem icon={<FaCoins />}>
              経費
              <Link to={routeConstants.expenses} />
            </MenuItem>
          )}
          {isMaster && (
            <MenuItem icon={<FaUserTie />}>
              役職
              <Link to={routeConstants.titles} />
            </MenuItem>
          )}
        </Menu>
      </SidebarContent>

      <SidebarFooter className="text-center">
        <Box py={5} className="sidebar-btn-wrapper">
          <Box d="block">
            <Switch
              height={16}
              width={30}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={handleCollapsedChange}
              checked={collapsed}
              onColor="#219de9"
              offColor="#bbbbbb"
            />
          </Box>
        </Box>
      </SidebarFooter>
    </ProSidebar>
  );
};
