import { CurrentUser } from "interfaces";
import { useState } from "react";

const ONE_WEEK_TTL = 1_000 * 24 * 60 * 60; // 1 week
const CURRENT_USER_KEY = "currentUser";

const setWithExpiry = (key: string, value: CurrentUser): void => {
  const now = new Date();
  const item = {
    ...value,
    expiry: now.getTime() + ONE_WEEK_TTL,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

const getCurrentUserFromLocalStage = (localString: string): CurrentUser | null => {
  const parsed = JSON.parse(localString) as CurrentUser & { expiry: number };
  const { expiry, ...rest } = parsed;
  const now = new Date();
  if (now.getTime() > expiry) {
    localStorage.removeItem(CURRENT_USER_KEY);

    return null;
  }

  return rest;
};

// https://medium.com/ovrsea/token-authentication-with-react-and-apollo-client-a-detailed-example-a3cc23760e9
export const useCurrentUser = (): {
  setCurrentUser: (currentUser: CurrentUser, save: boolean) => void;
  removeCurrentUser: () => void;
  currentUser: CurrentUser | null;
  logout: () => void;
} => {
  const getCurrentUser = (): CurrentUser | null => {
    const currentUserStringLocal = localStorage.getItem(CURRENT_USER_KEY);
    if (currentUserStringLocal) {
      return getCurrentUserFromLocalStage(currentUserStringLocal);
    }

    const currentUserString = sessionStorage.getItem(CURRENT_USER_KEY);
    if (!currentUserString) return null;
    const parsed = JSON.parse(currentUserString) as CurrentUser;

    return parsed;
  };

  const [currentUser, setCurrentUser] = useState(getCurrentUser());

  const saveCurrentUser = (currentUser: CurrentUser, save: boolean): void => {
    setCurrentUser(currentUser);
    if (save) {
      setWithExpiry(CURRENT_USER_KEY, currentUser);

      return;
    }

    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
  };

  const removeCurrentUser = (): void => {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
  };

  const logout = (): void => {
    removeCurrentUser();

    window.location.reload();
  };

  return {
    setCurrentUser: saveCurrentUser,
    removeCurrentUser,
    currentUser,
    logout,
  };
};
