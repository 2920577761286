import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { DEFAULT_STAFF_RESERVATION_FEE, DEFAULT_TABLE_CHARGE_COUNT } from "@kg-pos/common";
import { CreateStoreInput, StoreResponse, UpdateStoreInput } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { StoreSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

type StoreFormProps = {
  submit: (input: CreateStoreInput | UpdateStoreInput) => Promise<void>;
  data?: StoreResponse;
};

export const StoreForm: React.VFC<StoreFormProps> = ({ submit, data }) => {
  const { goBack } = useHistory();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: data?.name,
      description: data?.description,
      receiptPrinterIpAddress: data?.receiptPrinterIpAddress,
      serviceFee: data?.serviceFee,
      orderItemLockTimeHours: data?.orderItemLockTime?.substring(0, 2),
      orderItemLockTimeMinutes: data?.orderItemLockTime?.substring(2, 4),
      staffReservationFee: data?.staffReservationFee ?? DEFAULT_STAFF_RESERVATION_FEE,
      defaultTableChargeCount: data?.defaultTableChargeCount ?? DEFAULT_TABLE_CHARGE_COUNT,
      notificationEmailAddress: data?.notificationEmailAddress,
    },
  });
  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: StoreSubmit): Promise<void> => {
    const { orderItemLockTimeHours, orderItemLockTimeMinutes, ...rest } = input;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const orderItemLockTime = orderItemLockTimeHours + orderItemLockTimeMinutes;
    await submit({ ...rest, orderItemLockTime });

    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    goBack();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          店舗名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="店舗名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "店舗名が必要です。",
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.description} mt={6}>
        <FormLabel htmlFor="description">説明</FormLabel>
        <Input
          id="description"
          placeholder="説明を入力してください。"
          maxW="400px"
          {...register("description", {
            maxLength: {
              value: 500,
              message: "500文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.receiptPrinterIpAddress} mt={6}>
        <FormLabel htmlFor="receiptPrinterIpAddress">レシートプリンタのIPアドレス</FormLabel>
        <Input
          id="receiptPrinterIpAddress"
          placeholder="例）192.168.192.168"
          maxW="400px"
          {...register("receiptPrinterIpAddress", {
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.receiptPrinterIpAddress?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.serviceFee} mt={6}>
        <FormLabel htmlFor="serviceFee">
          サービス料(%)
          <RequireField />
        </FormLabel>
        <Input
          id="serviceFee"
          type="number"
          placeholder="サービス料を入力してください。"
          maxW="400px"
          {...register("serviceFee", {
            valueAsNumber: true,
            required: "サービス料が必要です。",
            min: { value: 0, message: "0以上で入力してください。" },
            max: { value: 100, message: "100以下で入力してください。" },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.serviceFee?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        id="orderItemLockTime"
        isInvalid={!!errors.orderItemLockTimeHours || !!errors.orderItemLockTimeMinutes}
        mt={6}
        maxW="400px"
      >
        <FormLabel>
          注文ロック時間
          <RequireField />
        </FormLabel>
        <Flex>
          <Select
            defaultValue="23"
            pr={1}
            {...register("orderItemLockTimeHours", {
              required: "注文ロック時間を選択してください。",
            })}
          >
            {[
              "00",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
            ].map((option, key) => (
              <option key={key} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Select
            defaultValue="45"
            pl={1}
            {...register("orderItemLockTimeMinutes", {
              required: "注文ロック時間を選択してください。",
            })}
          >
            {["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"].map(
              (option, key) => (
                <option key={key} value={option}>
                  {option}
                </option>
              )
            )}
          </Select>
        </Flex>
        <FormErrorMessage>{errors.orderItemLockTimeHours?.message}</FormErrorMessage>
        <FormErrorMessage>{errors.orderItemLockTimeMinutes?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.staffReservationFee} mt={6}>
        <FormLabel htmlFor="staffReservationFee">
          指名料
          <RequireField />
        </FormLabel>
        <Input
          id="staffReservationFee"
          type="number"
          placeholder="指名料を入力してください。"
          maxW="400px"
          {...register("staffReservationFee", {
            valueAsNumber: true,
            required: "指名料が必要です。",
            min: {
              value: 0,
              message: "0円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.staffReservationFee?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.defaultTableChargeCount} mt={6}>
        <FormLabel htmlFor="defaultTableChargeCount">
          テーブルチャージ数量
          <RequireField />
        </FormLabel>
        <Input
          id="defaultTableChargeCount"
          type="number"
          placeholder="テーブルチャージ数量を入力してください。"
          maxW="400px"
          {...register("defaultTableChargeCount", {
            valueAsNumber: true,
            required: "テーブルチャージ数量が必要です。",
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 100,
              message: "100以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.defaultTableChargeCount?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.notificationEmailAddress} mt={6}>
        <FormLabel htmlFor="notificationEmailAddress">注文通知メールアドレス</FormLabel>
        <Input
          id="notificationEmailAddress"
          type="email"
          placeholder="メールアドレスを入力してください。"
          maxW="400px"
          {...register("notificationEmailAddress", {
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.notificationEmailAddress?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
