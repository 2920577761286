import { Box, Button } from "@chakra-ui/react";
import { TitleResponse, useGetTitlesQuery } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { MainTable } from "components/molecules/main-table";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { routeConstants } from "routes";

export const columns: Array<Column> = [
  {
    Header: "役職名",
    accessor: "name",
  },
  {
    Header: "表示優先度",
    accessor: "sortNo",
  },
];

export const TitlesPage: React.VFC = () => {
  const history = useHistory();
  useTitle("役職");
  const { loading, error, data } = useGetTitlesQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (row: TitleResponse): void =>
    history.push(`${routeConstants.titles}/${row.id}`);

  const handleRegister = (): void => history.push(`${routeConstants.titles}/new`);

  const titles = data?.titles ?? [];

  if (loading) return <LoadingLayout />;
  if (error) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="役職"
        breadCrumbItems={[{ label: "役職", path: routeConstants.titles, active: true }]}
      />
      <MainCardContainer>
        <Button colorScheme="blue" onClick={handleRegister}>
          役職を登録する
        </Button>
        <Box pt="8">
          <MainTable data={titles} columns={columns} handleClick={handleClick} />
        </Box>
      </MainCardContainer>
    </>
  );
};
