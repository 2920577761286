import { Box } from "@chakra-ui/react";
import { useGetInitialByIdQuery, useUpdateInitialMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { InitialForm } from "components/organisms/initial-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { InitialSubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const InitialEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("初回編集");
  const { loading, error, data } = useGetInitialByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateInitial] = useUpdateInitialMutation();

  const initial = data?.initial;

  if (loading) return <LoadingLayout />;
  if (error || !initial) return <ErrorLayout />;

  const submit = async (input: InitialSubmit): Promise<void> => {
    await updateInitial({ variables: { id, ...input } });
  };

  // TODO:
  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="初回編集"
        breadCrumbItems={[
          { label: "初回", path: `${routeConstants.stores}/${initial.storeId}` },
          {
            label: "初回詳細",
            path: `${routeConstants.initials}/${id}`,
          },
          {
            label: "初回編集",
            path: `${routeConstants.storeTables}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <InitialForm backToTable={backToTable} submit={submit} data={initial} />
        </Box>
      </MainCardContainer>
    </>
  );
};
