import { Box } from "@chakra-ui/react";
import {
  useGetInformationCenterQuery,
  useUpdateInformationCenterMutation,
} from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { InformationCenterForm } from "components/organisms/information-center-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { InformationCenterSubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const InformationCenterEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("案内所編集");
  const { loading, error, data } = useGetInformationCenterQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateInformationCenter] = useUpdateInformationCenterMutation();

  const informationCenter = data?.getInformationCenter;

  if (loading) return <LoadingLayout />;
  if (error || !informationCenter) return <ErrorLayout />;

  const submit = async (input: InformationCenterSubmit): Promise<void> => {
    await updateInformationCenter({ variables: { id, ...input } });
  };

  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="案内所編集"
        breadCrumbItems={[
          { label: "案内所", path: `${routeConstants.stores}/${informationCenter.storeId}` },
          {
            label: "案内所詳細",
            path: `${routeConstants.penalties}/${id}`,
          },
          {
            label: "案内所編集",
            path: `${routeConstants.penalties}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <InformationCenterForm
            submit={submit}
            data={informationCenter}
            backToTable={backToTable}
          />
        </Box>
      </MainCardContainer>
    </>
  );
};
