export enum InputHostStatus {
  ACTIVE = "勤務中",
  LEAVING = "休職中",
  RESIGNED = "退店済",
}

export const hostStatusOptions = Object.entries(InputHostStatus).map((status) => ({
  label: status[1],
  value: status[0],
}));

export const DEFAULT_TITLE = "役職なし";
