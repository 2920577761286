import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import reportWebVitals from "components/atoms/reportWebVitals";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import theme from "./common/util/theme";
import * as serviceWorker from "./serviceWorker";
import "react-pro-sidebar/dist/scss/styles.scss";
import "./assets/styles/sidebar.scss";
import "./assets/styles/index.css";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
