import { Box } from "@chakra-ui/react";
import { useGetDeviceByIdQuery, useUpdateDeviceMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { DeviceForm } from "components/organisms/device-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { DeviceUpdateSubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const DeviceEditPage: React.VFC = () => {
  const { goBack } = useHistory();
  const id = useParams();
  useTitle("端末編集");
  const { loading, error, data } = useGetDeviceByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateDevice] = useUpdateDeviceMutation();

  const submit = async (input: DeviceUpdateSubmit) => {
    // 空パスワードに更新しないようにする
    if (input.hasOwnProperty("password") && input.password === "") {
      delete input.password;
    }
    await updateDevice({ variables: { id, ...input } });
  };

  const backToTable = (): void => goBack();

  const device = data?.device;

  if (loading) return <LoadingLayout />;
  if (error || !device) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="端末編集"
        breadCrumbItems={[
          { label: "端末", path: `${routeConstants.stores}/${device.storeId}` },
          {
            label: "端末詳細",
            path: `${routeConstants.devices}/${id}`,
          },
          {
            label: "端末編集",
            path: `${routeConstants.devices}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <DeviceForm submit={submit} data={device} backToTable={backToTable} />
        </Box>
      </MainCardContainer>
    </>
  );
};
