import { Box } from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";

type MobileToggleProps = {
  handleToggleSidebar: (value: boolean) => void;
};

export const MobileToggle: React.VFC<MobileToggleProps> = ({ handleToggleSidebar }) => (
  <Box ml={2} className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
    <FaBars />
  </Box>
);
