import { Box } from "@chakra-ui/react";
import { useGetPenaltyQuery, useUpdatePenaltyMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { PageTitle } from "components/organisms/page-title";
import { PenaltyForm } from "components/organisms/penalty-form";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { PenaltySubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const PenaltyEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("罰金編集");
  const { loading, error, data } = useGetPenaltyQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updatePenalty] = useUpdatePenaltyMutation();

  const penalty = data?.penalty;

  if (loading) return <LoadingLayout />;
  if (error || !penalty) return <ErrorLayout />;

  const submit = async (input: PenaltySubmit): Promise<void> => {
    await updatePenalty({ variables: { id, ...input } });
  };

  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="罰金編集"
        breadCrumbItems={[
          { label: "罰金", path: `${routeConstants.stores}/${penalty.storeId}` },
          {
            label: "罰金詳細",
            path: `${routeConstants.penalties}/${id}`,
          },
          {
            label: "罰金編集",
            path: `${routeConstants.penalties}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <PenaltyForm submit={submit} data={penalty} backToTable={backToTable} />
        </Box>
      </MainCardContainer>
    </>
  );
};
