import { Image } from "@chakra-ui/react";
import React from "react";
import LazyLoad from "react-lazyload";

type ListItemValueProps = {
  label: string;
  value: string | number | undefined | null;
};

export const ListItemValue: React.VFC<ListItemValueProps> = ({ label, value }) => {
  if (label === "アイコン") {
    return (
      <>
        {label}
        <LazyLoad height={100}>
          <Image
            boxSize="100px"
            src={value as string}
            alt="icon"
            mt={6}
            fallbackSrc="https://via.placeholder.com/150"
          />
        </LazyLoad>
      </>
    );
  }

  return (
    <>
      {label}: {value}
    </>
  );
};
