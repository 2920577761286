import { AdminPage } from "pages/admin";
import { AdminEditPage } from "pages/admin-edit";
import { CustomerPage } from "pages/customer";
import { CustomerEditPage } from "pages/customer-edit";
import { DevicePage } from "pages/device";
import { DeviceEditPage } from "pages/device-edit";
import { ExpensePage } from "pages/expense";
import { ExpenseEditPage } from "pages/expense-edit";
import { ExpenseNewPage } from "pages/expense-new";
import { ExpensesPage } from "pages/expenses";
import { HostPage } from "pages/host";
import { HostEditPage } from "pages/host-edit";
import { HostsByTitlePage } from "pages/hosts-by-title";
import { InformationCenterPage } from "pages/information-center";
import { InformationCenterEditPage } from "pages/information-center-edit";
import { InitialPage } from "pages/initial";
import { InitialEditPage } from "pages/initial-edit";
import { ItemPage } from "pages/item";
import { ItemEditPage } from "pages/item-edit";
import { NotFound } from "pages/not-found";
import { PenaltyPage } from "pages/penalty";
import { PenaltyEditPage } from "pages/penalty-edit";
import { SettingPage } from "pages/setting";
import { SettingEditPage } from "pages/setting-edit";
import { StorePage } from "pages/store";
import { StoreEditPage } from "pages/store-edit";
import { StoreNewPage } from "pages/store-new";
import { StoreTablePage } from "pages/store-table";
import { StoreTableEditPage } from "pages/store-table-edit";
import { StoresPage } from "pages/stores";
import { TitlePage } from "pages/title";
import { TitleEditPage } from "pages/title-edit";
import { TitleNewPage } from "pages/title-new";
import { TitlesPage } from "pages/titles";

export const routeConstants = {
  stores: "/stores",
  items: "/items",
  initials: "/initials",
  penalties: "/penalties",
  informationCenters: "/informationCenters",
  expenses: "/expenses",
  hosts: "/hosts",
  customers: "/customers",
  storeTables: "/storeTables",
  devices: "/devices",
  admins: "/admins",
  titles: "/titles",
  setting: "/setting",
  hostsByTitle: "/hostsByTitle",
};

export const routes = [
  {
    path: `${routeConstants.stores}/new`,
    component: StoreNewPage,
    exact: true,
  },
  {
    path: `${routeConstants.stores}/edit/:id`,
    component: StoreEditPage,
  },
  {
    path: `${routeConstants.stores}/:id`,
    component: StorePage,
  },
  {
    path: `${routeConstants.penalties}/edit/:id`,
    component: PenaltyEditPage,
  },
  {
    path: `${routeConstants.penalties}/:id`,
    component: PenaltyPage,
  },
  {
    path: `${routeConstants.informationCenters}/edit/:id`,
    component: InformationCenterEditPage,
  },
  {
    path: `${routeConstants.informationCenters}/:id`,
    component: InformationCenterPage,
  },
  {
    path: routeConstants.expenses,
    component: ExpensesPage,
    exact: true,
  },
  {
    path: `${routeConstants.expenses}/new`,
    component: ExpenseNewPage,
    exact: true,
  },
  {
    path: `${routeConstants.expenses}/edit/:id`,
    component: ExpenseEditPage,
  },
  {
    path: `${routeConstants.expenses}/:id`,
    component: ExpensePage,
  },
  {
    path: routeConstants.titles,
    component: TitlesPage,
    exact: true,
  },
  {
    path: `${routeConstants.titles}/new`,
    component: TitleNewPage,
    exact: true,
  },
  {
    path: `${routeConstants.titles}/edit/:id`,
    component: TitleEditPage,
  },
  {
    path: `${routeConstants.titles}/:id`,
    component: TitlePage,
  },
  {
    path: `${routeConstants.items}/edit/:id`,
    component: ItemEditPage,
  },
  {
    path: `${routeConstants.items}/:id`,
    component: ItemPage,
  },
  {
    path: `${routeConstants.hosts}/edit/:id`,
    component: HostEditPage,
  },
  {
    path: `${routeConstants.hosts}/:id`,
    component: HostPage,
  },
  {
    path: `${routeConstants.hostsByTitle}`,
    component: HostsByTitlePage,
  },
  {
    path: `${routeConstants.customers}/edit/:id`,
    component: CustomerEditPage,
  },
  {
    path: `${routeConstants.customers}/:id`,
    component: CustomerPage,
  },
  {
    path: `${routeConstants.initials}/edit/:id`,
    component: InitialEditPage,
  },
  {
    path: `${routeConstants.initials}/:id`,
    component: InitialPage,
  },
  {
    path: `${routeConstants.storeTables}/edit/:id`,
    component: StoreTableEditPage,
  },
  {
    path: `${routeConstants.storeTables}/:id`,
    component: StoreTablePage,
  },
  {
    path: `${routeConstants.devices}/edit/:id`,
    component: DeviceEditPage,
  },
  {
    path: `${routeConstants.devices}/:id`,
    component: DevicePage,
  },
  {
    path: `${routeConstants.admins}/edit/:id`,
    component: AdminEditPage,
  },
  {
    path: `${routeConstants.admins}/:id`,
    component: AdminPage,
  },
  {
    path: `${routeConstants.setting}/edit`,
    component: SettingEditPage,
  },
  {
    path: `${routeConstants.setting}`,
    component: SettingPage,
  },
  {
    path: "/",
    component: StoresPage,
    exact: true,
  },
  {
    // path: "/",
    component: NotFound,
  },
];
