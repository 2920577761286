import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import {
  InformationCenterResponse,
  useCreateInformationCenterMutation,
  useGetInformationCentersByStoreIdQuery,
} from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { InformationCenterForm } from "components/organisms/information-center-form";
import { StoreContext } from "contexts/store.context";
import { InformationCenterSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "名称",
    accessor: "name",
  },
  {
    Header: "表示優先度",
    accessor: "sortNo",
  },
];

export const InformationCenterLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetInformationCentersByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createInformationCenter] = useCreateInformationCenterMutation();

  const backToTable = async (): Promise<void> => {
    setForm(false);
    await refetch();
  };

  const handleClick = (row: InformationCenterResponse): void => {
    push(`${routeConstants.informationCenters}/${row.id}?storeId=${storeId}`);
  };

  const submit = async (input: InformationCenterSubmit): Promise<void> => {
    await createInformationCenter({
      variables: { storeId, ...input },
    });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  const informationCenters = data?.getInformationCentersByStoreId ?? [];

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <InformationCenterForm backToTable={backToTable} submit={submit} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            案内所を登録する
          </Button>
          <MainTable data={informationCenters} columns={columns} handleClick={handleClick} />
        </>
      )}
    </>
  );
};
