import { AdminResponse, useGetAdminQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const SettingPage: React.VFC = () => {
  useTitle("設定");
  const { loading, error, data } = useGetAdminQuery({
    fetchPolicy: "cache-and-network",
  });

  const admin = data?.admin as AdminResponse;

  if (loading) return <LoadingLayout />;
  if (error || !admin) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="設定"
        breadCrumbItems={[{ label: "設定", path: routeConstants.setting, active: true }]}
      />

      <SingleRead
        data={[
          { label: "アカウント名", value: admin.account },
          { label: "パスワード", value: "********" },
          { label: "担当店舗", value: admin.isMaster ? "総合管理者" : admin.store?.name },
        ]}
        editPath={`${routeConstants.setting}/edit`}
      />
    </>
  );
};
