import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

type DeleteModalProps = {
  title: string;
  name: string;
  handleDelete: () => void;
};

export const DeleteModal: React.VFC<DeleteModalProps> = ({ title, name, handleDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button bg="red" color="white" onClick={onOpen}>
        削除する
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <p>{name}を削除します。この動作は戻せません。</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              削除
            </Button>
            <Button onClick={onClose}>キャンセル</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
