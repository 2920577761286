import { Box } from "@chakra-ui/react";
import { useGetItemQuery, useUpdateItemMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { ItemForm } from "components/organisms/item-form";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import { ItemSubmit } from "interfaces";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { routeConstants } from "routes";

export const ItemEditPage: React.VFC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  useTitle("商品編集");
  const { loading, error, data } = useGetItemQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateItem] = useUpdateItemMutation();

  const item = data?.item;

  if (loading) return <LoadingLayout />;
  if (error) return <ErrorLayout />;
  if (!item) return <ErrorLayout />;

  const submit = async (input: ItemSubmit): Promise<void> => {
    await updateItem({ variables: { id, ...input } });
  };

  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="商品編集"
        breadCrumbItems={[
          { label: "商品", path: `${routeConstants.stores}/${item.storeId}` },
          {
            label: "商品詳細",
            path: `${routeConstants.items}/${id}`,
          },
          {
            label: "商品編集",
            path: `${routeConstants.items}/edit/${id}`,
            active: true,
          },
        ]}
      />
      <MainCardContainer>
        <Box>
          <ItemForm submit={submit} backToTable={backToTable} data={item} />
        </Box>
      </MainCardContainer>
    </>
  );
};
