import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

export const useParams = (): string => {
  const match = useRouteMatch();

  const id = useMemo((): string => {
    if ("id" in match.params) {
      const params = match.params as { id: string };

      return params.id;
    }

    return "";
  }, [match.params]);

  return id;
};
