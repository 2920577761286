import { Box, Button } from "@chakra-ui/react";
import { useDeleteTitleMutation, useGetTitleQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const TitlePage: React.VFC = () => {
  const { push } = useHistory();
  const id = useParams();
  useTitle("役職詳細");
  const { loading, error, data } = useGetTitleQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteTitle] = useDeleteTitleMutation();

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteTitle({ variables: { id } })).data?.deleteTitle;
  };

  const title = data?.title;

  const handleHostList = (): void => void push(`${routeConstants.hostsByTitle}?titleId=${id}`);

  if (loading) return <LoadingLayout />;
  if (error || !title) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="役職詳細"
        breadCrumbItems={[
          { label: "役職", path: routeConstants.titles },
          {
            label: "役職詳細",
            path: `${routeConstants.titles}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "役職名", value: title.name },
          { label: "表示優先度", value: title.sortNo },
        ]}
        editPath={`${routeConstants.titles}/edit/${id}`}
        deletePath={`${routeConstants.titles}`}
        handleDelete={handleDelete}
      />

      <Box pt="8">
        <Button colorScheme="teal" variant="outline" onClick={() => handleHostList()}>
          ホスト一覧
        </Button>
      </Box>
    </>
  );
};
