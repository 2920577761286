import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { RequireField } from "components/atoms/require-field";
import { InputOption } from "interfaces";
import React from "react";
import type { DeepMap, FieldError, UseFormRegister } from "react-hook-form";

type StoreSelectProps = {
  register: UseFormRegister<any>;
  stores: InputOption[];
  currentStoreId: string;
  errors: DeepMap<any, FieldError>;
};

export const StoreSelect: React.VFC<StoreSelectProps> = ({
  register,
  stores,
  currentStoreId,
  errors,
}) => {
  return (
    <FormControl id="storeId" isInvalid={!!errors.storeId} mt={6} maxW="400px">
      <FormLabel>
        店舗
        <RequireField />
      </FormLabel>
      <Select
        defaultValue={currentStoreId}
        placeholder="店舗を選択してください。"
        {...register("storeId", {
          required: "店舗が必要です。",
        })}
        maxW="400px"
      >
        {stores.map((store) => (
          <option key={store.value} value={store.value}>
            {store.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors.storeId?.message}</FormErrorMessage>
    </FormControl>
  );
};
