import { Breadcrumb, BreadcrumbItem, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

type PageTitleProps = {
  title: string;
  breadCrumbItems?: { label: string; path: string; active?: boolean; onClick?: () => void }[];
};

export const PageTitle: React.FC<PageTitleProps> = ({ title, breadCrumbItems }) => (
  <Flex align="center">
    <Text fontSize="2xl" fontWeight={500} py={6} flexGrow={1}>
      {title}
    </Text>

    <div>
      <Breadcrumb>
        <BreadcrumbItem color="blue">
          <Link to="/">ホーム</Link>
        </BreadcrumbItem>

        {breadCrumbItems?.map((item, index) => (
          <BreadcrumbItem key={index} color={item.active ? "black" : "blue"}>
            <Link to={item.path} onClick={item.onClick}>
              {item.label}
            </Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  </Flex>
);
