import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { StoreTableResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { StoreTableSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type StoreTableFormProps = {
  backToTable: () => void;
  submit: (input: StoreTableSubmit) => Promise<void>;
  data?: StoreTableResponse;
};

export const StoreTableForm: React.VFC<StoreTableFormProps> = ({ backToTable, submit, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { name: data?.name, description: data?.description },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: StoreTableSubmit): Promise<void> => {
    await submit(input);
    toast({
      title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          テーブル名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="テーブル名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "テーブル名が必要です。",
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.description} mt={6}>
        <FormLabel htmlFor="description">説明</FormLabel>
        <Input
          id="description"
          placeholder="説明を入力してください。"
          maxW="400px"
          {...register("description", {})}
        />
        <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
