import { Box, Button } from "@chakra-ui/react";
import { ExpenseResponse, useGetExpenseItemsQuery } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { MainTable } from "components/molecules/main-table";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";
import { routeConstants } from "routes";

export const columns: Array<Column> = [
  {
    Header: "項目名",
    accessor: "name",
  },
  {
    Header: "経費例",
    accessor: "examples",
  },
  {
    Header: "表示優先度",
    accessor: "sortNo",
  },
];

export const ExpensesPage: React.VFC = () => {
  const history = useHistory();
  useTitle("経費");
  const { loading, error, data } = useGetExpenseItemsQuery({
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (row: ExpenseResponse): void =>
    history.push(`${routeConstants.expenses}/${row.id}`);

  const handleRegister = (): void => history.push(`${routeConstants.expenses}/new`);

  const expenses = data?.expenseItems ?? [];

  if (loading) return <LoadingLayout />;
  if (error) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="経費"
        breadCrumbItems={[{ label: "経費", path: routeConstants.expenses, active: true }]}
      />
      <MainCardContainer>
        <Button colorScheme="blue" onClick={handleRegister}>
          経費を登録する
        </Button>
        <Box pt="8">
          <MainTable data={expenses} columns={columns} handleClick={handleClick} />
        </Box>
      </MainCardContainer>
    </>
  );
};
