import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { RequireField } from "components/atoms/require-field";
import { TitleSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export const TitleForm = ({
  submit,
  path,
  data,
}: {
  submit: (values: TitleSubmit) => Promise<void>;
  path: string;
  data?: { id: string; name: string; sortNo: number };
}) => {
  const history = useHistory();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { name: data?.name, sortNo: data?.sortNo } });

  const isEdit = useMemo(() => {
    return !!data;
  }, [data]);

  const onSubmit = async ({ name, sortNo }: TitleSubmit) => {
    await submit({ name, sortNo });
    toast({
      title: isEdit ? `${name}を編集しました。` : `${name}を新規作成しました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    history.push(path);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          役職名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="役職名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "役職名が必要です。",
            maxLength: { value: 100, message: "100文字以内で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="sortNo" mt={6} maxW="400px" isInvalid={!!errors.sortNo}>
        <FormLabel>
          表示優先度
          <RequireField />
        </FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を入力してください。"
          defaultValue={100}
          {...register("sortNo", {
            required: "表示優先度が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
