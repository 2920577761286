import { HostResponse, HostStatus } from "__generated__/graphql";
import { formatted } from "common/util/date";
import { hostStatusOptions, InputHostStatus } from "constant/host";

export const getHostStatus = (status: HostStatus): string =>
  hostStatusOptions.find((option) => option.value === status)?.label ?? InputHostStatus.ACTIVE;

export const getHostRecord = (host: HostResponse | undefined) => {
  if (!host) return host;

  const { hostIsNumber, startDate, status, title } = host;

  return {
    ...host,
    title: title?.name,
    hostIsNumber: hostIsNumber ? "○" : "×",
    // ex: "2019-12-03T09:54:33Z"
    startDate: formatted(startDate as string | null),
    status: getHostStatus(status),
  };
};

export const getHostByTitleRecord = (host: HostResponse | undefined) => {
  if (!host) return host;

  const { status, title, store, sales, appoints } = host;

  return {
    ...host,
    appoints: appoints ?? 0,
    sales,
    title: title?.name,
    status: getHostStatus(status),
    storeName: store?.name,
  };
};

export const sortByhostSales = (hostA: any, hostB: any) => {
  if ((hostA.sales ?? 0) > (hostB.sales ?? 0)) return -1;
  if ((hostA.sales ?? 0) < (hostB.sales ?? 0)) return 1;

  return 0;
};
