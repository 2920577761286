import { Box } from "@chakra-ui/react";
import { useCreateExpenseItemMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ExpenseForm } from "components/organisms/expense-form";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import { ExpenseSubmit } from "interfaces";
import React from "react";
import { routeConstants } from "routes";

export const ExpenseNewPage: React.VFC = () => {
  useTitle("経費登録");
  const [createExpenseItem] = useCreateExpenseItemMutation();
  const submit = async (input: ExpenseSubmit): Promise<void> => {
    await createExpenseItem({ variables: input });
  };

  return (
    <>
      <PageTitle
        title="経費登録"
        breadCrumbItems={[
          { label: "経費", path: routeConstants.expenses },
          { label: "経費登録", path: `${routeConstants.expenses}/new`, active: true },
        ]}
      />
      <MainCardContainer>
        <Box>
          <ExpenseForm submit={submit} path={routeConstants.expenses} />
        </Box>
      </MainCardContainer>
    </>
  );
};
