import { Flex, Text } from "@chakra-ui/react";
import React from "react";

export const ErrorLayout: React.VFC = () => (
  <Flex align="center">
    <Text fontSize="2xl" fontWeight={500} py={6} flexGrow={1}>
      エラーが起きました。
    </Text>
  </Flex>
);
