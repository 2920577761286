import { InputOption } from "interfaces";

export const generateOption = (value: string, label?: string): InputOption => {
  if (!label) return { label: value, value };

  return { label, value };
};

export const getOption = (options: InputOption[], value?: string): InputOption | undefined => {
  if (!value) return undefined;

  const finded = options.find((option) => option.value === value);
  if (!finded) {
    throw Error("invalid option.");
  }

  return finded;
};

export const getOptions = <T, K extends keyof T>(
  records: Array<T & { id: string }>,
  key: K
): InputOption[] => {
  const options = records.map((record) => ({
    label: String(record[key]),
    value: String(record.id),
  }));

  return options;
};
