import {
  useDeleteInformationCenterMutation,
  useGetInformationCenterQuery,
} from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const InformationCenterPage: React.VFC = () => {
  const id = useParams();
  useTitle("案内所");
  const { loading, error, data } = useGetInformationCenterQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteInformationCenter] = useDeleteInformationCenterMutation();

  const informationCenter = data?.getInformationCenter;

  if (loading) return <LoadingLayout />;
  if (error || !informationCenter) return <ErrorLayout />;

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteInformationCenter({ variables: { id } })).data?.deleteInformationCenter;
  };

  return (
    <>
      <PageTitle
        title="案内所詳細"
        breadCrumbItems={[
          { label: "案内所", path: `${routeConstants.stores}/${informationCenter.storeId}` },
          {
            label: "案内所詳細",
            path: `${routeConstants.informationCenters}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名称", value: informationCenter.name },
          { label: "表示優先度", value: informationCenter.sortNo },
        ]}
        editPath={`${routeConstants.informationCenters}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${informationCenter.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
