import { Box, Button, Flex, List, ListIcon, ListItem, useToast } from "@chakra-ui/react";
import { ListItemValue } from "components/atoms/list-item-value";
import { MainCardContainer } from "components/containers/main-card";
import { DeleteModal } from "components/organisms/delete-modal";
import React from "react";
import { MdLabel } from "react-icons/md";
import { useHistory } from "react-router-dom";

type SingleReadProps = {
  data: { label: string; value: string | number | undefined | null }[];
  editPath?: string;
  deletePath?: string;
  handleDelete?: () => Promise<boolean>;
  noContainer?: boolean;
  name?: string;
};

export const SingleRead: React.VFC<SingleReadProps> = ({
  data,
  editPath,
  deletePath,
  handleDelete,
  noContainer,
  name,
}) => {
  const history = useHistory();
  const toast = useToast();

  name = name ?? (typeof data[0].value === "string" ? data[0].value : "");

  const onEditClick = (): void => {
    if (editPath) {
      history.push(editPath);
    }
  };

  const onDeleteClick = async (): Promise<void> => {
    if (!deletePath || !handleDelete) {
      return;
    }

    if (await handleDelete()) {
      toast({
        title: `${name}を削除しました。`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: `${name}の削除に失敗しました。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deletePath === "goBack") {
      history.goBack();

      return;
    }

    history.replace(deletePath);
  };

  const main = (
    <Flex>
      <Box flexGrow={1}>
        <List spacing={3}>
          {data.map((d, index) => (
            <ListItem key={index}>
              <ListIcon as={MdLabel} color="gray.500" />
              <ListItemValue label={d.label} value={d.value} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        {editPath && (
          <Button mr={4} onClick={onEditClick}>
            編集する
          </Button>
        )}
        {deletePath && <DeleteModal title="削除" name={name} handleDelete={onDeleteClick} />}
      </Box>
    </Flex>
  );

  if (noContainer) {
    return main;
  }

  return <MainCardContainer>{main}</MainCardContainer>;
};
