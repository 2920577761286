import { Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

export const LoadingLayout: React.VFC = () => (
  <Stack>
    <Skeleton height="20px" mt={10} />
    <Skeleton height="20px" />
    <Skeleton height="20px" />
  </Stack>
);
