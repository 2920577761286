import { Box } from "@chakra-ui/react";
import { UpdateStoreInput, useGetStoreQuery, useUpdateStoreMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { PageTitle } from "components/organisms/page-title";
import { StoreForm } from "components/organisms/store-form";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const StoreEditPage: React.VFC = () => {
  const id = useParams();
  useTitle("店舗編集");
  const { loading, error, data } = useGetStoreQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateStore] = useUpdateStoreMutation();

  const store = data?.store;

  if (loading) return <LoadingLayout />;
  if (error || !store) return <ErrorLayout />;

  const submit = async (input: UpdateStoreInput): Promise<void> => {
    await updateStore({
      variables: {
        id,
        ...input,
      },
    });
  };

  return (
    <>
      <PageTitle
        title="店舗編集"
        breadCrumbItems={[
          { label: "店舗", path: `${routeConstants.stores}/${store.id}` },
          {
            label: "店舗編集",
            path: `${routeConstants.stores}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <StoreForm submit={submit} data={store} />
        </Box>
      </MainCardContainer>
    </>
  );
};
