import { Box } from "@chakra-ui/react";
import {
  AdminResponse,
  UpdateAdminInput,
  useGetAdminQuery,
  useUpdateAdminMutation,
} from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { AdminForm } from "components/organisms/admin-form";
import { PageTitle } from "components/organisms/page-title";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const SettingEditPage: React.VFC = () => {
  const history = useHistory();
  useTitle("設定編集");
  const { loading, error, data } = useGetAdminQuery({
    fetchPolicy: "cache-and-network",
  });
  const [updateAdmin] = useUpdateAdminMutation();

  const admin = data?.admin as AdminResponse;

  if (loading) return <LoadingLayout />;
  if (error || !admin) return <ErrorLayout />;

  const submit = async (input: UpdateAdminInput): Promise<void> => {
    await updateAdmin({ variables: { ...input } });
  };

  const backToTable = (): void => history.goBack();

  return (
    <>
      <PageTitle
        title="設定編集"
        breadCrumbItems={[
          {
            label: "設定",
            path: routeConstants.setting,
          },
          {
            label: "設定編集",
            path: `${routeConstants.setting}/edit`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <AdminForm submit={submit} backToTable={backToTable} data={admin} />
        </Box>
      </MainCardContainer>
    </>
  );
};
