import { Box } from "@chakra-ui/react";
import { useGetExpenseItemQuery, useUpdateExpenseItemMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { ExpenseForm } from "components/organisms/expense-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { ExpenseSubmit } from "interfaces";
import React from "react";
import { routeConstants } from "routes";

export const ExpenseEditPage: React.VFC = () => {
  const id = useParams();
  useTitle("経費編集");
  const { loading, error, data } = useGetExpenseItemQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateExpenseItem] = useUpdateExpenseItemMutation();

  const expense = data?.expenseItem;

  if (loading) return <LoadingLayout />;
  if (error || !expense) return <ErrorLayout />;

  const submit = async (input: ExpenseSubmit): Promise<void> => {
    await updateExpenseItem({ variables: { id, ...input } });
  };

  return (
    <>
      <PageTitle
        title="経費編集"
        breadCrumbItems={[
          { label: "経費", path: routeConstants.expenses },
          {
            label: "経費編集",
            path: `${routeConstants.expenses}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <ExpenseForm submit={submit} path={`${routeConstants.expenses}/${id}`} data={expense} />
        </Box>
      </MainCardContainer>
    </>
  );
};
