import { Box } from "@chakra-ui/react";
import { useGetTitleQuery, useUpdateTitleMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { PageTitle } from "components/organisms/page-title";
import { TitleForm } from "components/organisms/title-form";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { TitleSubmit } from "interfaces";
import React from "react";
import { routeConstants } from "routes";

export const TitleEditPage: React.VFC = () => {
  const id = useParams();
  useTitle("役職編集");
  const { loading, error, data } = useGetTitleQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateTitle] = useUpdateTitleMutation();

  const title = data?.title;

  if (loading) return <LoadingLayout />;
  if (error || !title) return <ErrorLayout />;

  const submit = async ({ name, sortNo }: TitleSubmit): Promise<void> => {
    await updateTitle({ variables: { id, name, sortNo } });
  };

  return (
    <>
      <PageTitle
        title="役職編集"
        breadCrumbItems={[
          { label: "役職", path: routeConstants.titles },
          {
            label: "役職編集",
            path: `${routeConstants.titles}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <TitleForm submit={submit} path={`${routeConstants.titles}/${id}`} data={title} />
        </Box>
      </MainCardContainer>
    </>
  );
};
