import {
  Button,
  Select as ChakraSelect,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { DeviceTypeList } from "@kg-pos/common";
import { DeviceType, ItemResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { itemCategoryOptions } from "constant/item";
import { InputOption, ItemSubmit } from "interfaces";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";

type ItemFormProps = {
  submit: (input: ItemSubmit) => Promise<void>;
  backToTable: () => void;
  data?: ItemResponse;
};

export const ItemForm: React.VFC<ItemFormProps> = ({ backToTable, submit, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: data?.name,
      price: data?.price,
      category: data?.category,
      isFreeDrink: false,
      sortNo: data?.sortNo,
      bottleBack: data?.bottleBack,
      accessControl: data?.accessControl ?? [],
    },
  });
  const [selectedAccessControlOptions, setSelectedAccessControlOptions] = useState<InputOption[]>(
    data?.accessControl
      ? data.accessControl.map((ac) => ({ label: DeviceTypeList[ac], value: ac }))
      : []
  );

  // ホストと紐付く場内指名を区別する
  const isInsideCallForHost = !!data?.host;

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async (input: ItemSubmit): Promise<void> => {
    try {
      await submit(input);
      toast({
        title: isEdit ? `${input.name}を編集しました。` : `${input.name}を新規作成しました。`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      backToTable();
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(`cannot ${isEdit ? "update" : "create"} item`, error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">
          商品名
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          placeholder="商品名を入力してください。"
          maxW="400px"
          {...register("name", {
            required: "商品名が必要です。",
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
            // 完全否定(完全一致のみ除外)の正規表現
            // see: https://www.greptips.com/posts/1310/
            pattern: {
              value: /^(?!テーブルチャージ+$|セット+$)/,
              message: "使用できない名前です。",
            },
          })}
          disabled={isInsideCallForHost}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="category" isInvalid={!!errors.category} mt={6} maxW="400px">
        <FormLabel>
          カテゴリー
          <RequireField />
        </FormLabel>
        <ChakraSelect
          placeholder="カテゴリーを選択してください。"
          {...register("category", {
            required: "カテゴリーを選択してください。",
          })}
          disabled={isInsideCallForHost}
        >
          {itemCategoryOptions.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </ChakraSelect>
        <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.price} mt={6}>
        <FormLabel htmlFor="price">
          価格
          <RequireField />
        </FormLabel>
        <Input
          id="price"
          type="number"
          placeholder="価格を入力してください。"
          maxW="400px"
          {...register("price", {
            required: "価格が必要です。",
            valueAsNumber: true,
            min: {
              value: -50_000_000,
              message: "-5000万円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
      </FormControl>
      {!isEdit && (
        <FormControl isInvalid={!!errors.isFreeDrink} mt={6}>
          <Checkbox {...register("isFreeDrink")}>
            フリードリンク(チェックするとフリードリンクメニューが追加されます。)
          </Checkbox>
        </FormControl>
      )}
      <FormControl id="sortNo" mt={6} maxW="400px" isInvalid={!!errors.sortNo}>
        <FormLabel htmlFor="sortNo">
          表示優先度
          <RequireField />
        </FormLabel>
        <Input
          id="sortNo"
          type="number"
          placeholder="表示優先度を入力してください。"
          defaultValue={100}
          {...register("sortNo", {
            required: "表示優先度が必要です。",
            valueAsNumber: true,
            min: {
              value: 0,
              message: "0以上で入力してください。",
            },
            max: {
              value: 1_000_000_000,
              message: "1,000,000,000以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.sortNo?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.bottleBack} mt={6}>
        <FormLabel htmlFor="bottleBack">
          ボトルバック
          <RequireField />
        </FormLabel>
        <Input
          id="bottleBack"
          type="number"
          placeholder="ボトルバックを入力してください。"
          maxW="400px"
          defaultValue={0}
          {...register("bottleBack", {
            required: "ボトルバックが必要です。",
            valueAsNumber: true,
            min: {
              value: -50_000_000,
              message: "-5000万円以上で入力してください。",
            },
            max: {
              value: 50_000_000,
              message: "5000万円以下で入力してください。",
            },
            pattern: { value: /^\d+/, message: "数字で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.bottleBack?.message}</FormErrorMessage>
      </FormControl>
      <FormControl mt={6} isInvalid={!!errors.accessControl}>
        <FormLabel>アクセス制限</FormLabel>
        <Select
          {...register("accessControl")}
          placeholder="アクセス制限する端末タイプがあれば入力"
          options={[
            { label: DeviceTypeList[DeviceType.HALL], value: DeviceType.HALL },
            { label: DeviceTypeList[DeviceType.CASHIER], value: DeviceType.CASHIER },
            { label: DeviceTypeList[DeviceType.VIEWER], value: DeviceType.VIEWER },
            { label: DeviceTypeList[DeviceType.KITCHEN], value: DeviceType.KITCHEN },
            { label: DeviceTypeList[DeviceType.OFFICE_STAFF], value: DeviceType.OFFICE_STAFF },
          ]}
          value={selectedAccessControlOptions}
          onChange={(val) => {
            // MultiSelectの状態更新
            setSelectedAccessControlOptions(val as InputOption[]);
            // formの持つ値の更新
            setValue(
              "accessControl",
              (val as InputOption[]).map((v) => v.value as DeviceType)
            );
          }}
          isMulti
          // see: https://react-select.com/styles
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
              height: "40px",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              // Inputと中身の間の余白が気になったらここを調節
              // paddingLeft: "1rem",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              maxWidth: "400px",
            }),
          }}
        />
        <FormErrorMessage>{(errors.accessControl as any)?.message}</FormErrorMessage>
      </FormControl>

      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
