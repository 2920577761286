import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { config } from "configs";

const uri = config.graphql.url;

const httpLink = createHttpLink({
  uri,
});

const createAuthLink = (token: string | null) => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
};

const cache = new InMemoryCache({});

export const useAppApolloClient = (token: string | null) => {
  const authLink = createAuthLink(token);

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });
};
