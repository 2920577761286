import { Box } from "@chakra-ui/react";
import {
  UpdateCustomerInput,
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
} from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { CustomerForm } from "components/organisms/customer-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const CustomerEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("顧客編集");
  const { loading, error, data } = useGetCustomerByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateCustomer] = useUpdateCustomerMutation();

  const customer = data?.customerById;

  if (loading) return <LoadingLayout />;
  if (error || !customer) {
    console.log(error?.message);

    return <ErrorLayout />;
  }

  const submit = async (input: UpdateCustomerInput): Promise<void> => {
    await updateCustomer({ variables: { id, ...input } });
  };

  const backToTable = (): void => void history.goBack();

  return (
    <>
      <PageTitle
        title="顧客編集"
        breadCrumbItems={[
          { label: "顧客", path: `${routeConstants.stores}/${customer.storeId}` },
          {
            label: "顧客詳細",
            path: `${routeConstants.customers}/${id}`,
          },
          {
            label: "顧客編集",
            path: `${routeConstants.customers}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <CustomerForm backToTable={backToTable} submit={submit} data={customer} />
        </Box>
      </MainCardContainer>
    </>
  );
};
