import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { RequireField } from "components/atoms/require-field";
import { DEFAULT_TITLE } from "constant/host";
import { AuthContext } from "contexts/current-user.context";
import { InputOption } from "interfaces";
import React, { useContext, useState } from "react";
import type { DeepMap, FieldError, UseFormRegister } from "react-hook-form";

type TitleSelectProps = {
  register: UseFormRegister<any>;
  titles: InputOption[];
  errors: DeepMap<any, FieldError>;
};

export const TitleSelect: React.VFC<TitleSelectProps> = ({ register, titles, errors }) => {
  const [newTitle, setNewTitle] = useState(false);
  const currentUser = useContext(AuthContext).currentUser;
  if (!currentUser) return null;
  const { isMaster } = currentUser;

  if (newTitle) {
    return (
      <FormControl id="title" isInvalid={!!errors.title} mt={6} maxW="400px">
        <FormLabel>
          役職
          <RequireField />
          <Button colorScheme="teal" size="xs" ml={4} onClick={() => setNewTitle(false)}>
            選択する
          </Button>
        </FormLabel>
        <Input
          id="title"
          placeholder="役職を入力してください。"
          {...register("title", {
            required: "役職が必要です。",
            maxLength: {
              value: 100,
              message: "100文字以下で入力してください。",
            },
          })}
        />
        <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <FormControl id="titleId" isInvalid={!!errors.titleId} mt={6} maxW="400px">
      <FormLabel>
        役職
        <RequireField />
        {isMaster && (
          <Button colorScheme="teal" size="xs" ml={4} onClick={() => setNewTitle(true)}>
            新規作成する
          </Button>
        )}
      </FormLabel>
      <Select
        // 「役職なし」が登録されていたらデフォルト値として使用
        defaultValue={titles.find((title) => title.label === DEFAULT_TITLE)?.value}
        placeholder="役職を選択してください。"
        {...register("titleId", {
          required: "役職を選択してください。",
        })}
        maxW="400px"
      >
        {titles.map((title) => (
          <option key={title.value} value={title.value}>
            {title.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors.titleId?.message}</FormErrorMessage>
    </FormControl>
  );
};
