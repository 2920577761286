import { ArrowBackIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, Button, Stack, Text } from "@chakra-ui/react";
import { UNIQUE_VALIDATION } from "@kg-pos/common";
import {
  AdminResponse,
  AdminType,
  useCreateAdminMutation,
  useGetAdminsByStoreIdQuery,
} from "__generated__/graphql";
import { getAdminRecord } from "common/object/admin";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { AdminForm } from "components/organisms/admin-form";
import { StoreContext } from "contexts/store.context";
import { AdminSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "管理者アカウント名",
    accessor: "account",
  },
  {
    Header: "管理者タイプ",
    accessor: "type",
  },
];

export const AdminLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetAdminsByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createAdmin, { error: createAdminError }] = useCreateAdminMutation();

  const backToTable = async (): Promise<void> => {
    setForm(false);

    await refetch();
  };

  const handleClick = (row: AdminResponse): void => {
    push(`${routeConstants.admins}/${row.id}?storeId=${storeId}`);
  };

  const submit = async (input: AdminSubmit): Promise<void> => {
    if (input.type === AdminType.COLLECTOR) {
      input.isCollector = true;
    }
    await createAdmin({
      variables: { storeId, ...input },
    });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          {createAdminError && (
            <>
              <Text fontSize="sm" color="red.500" mb={2}>
                管理者登録に失敗しました。
                {createAdminError?.message.includes(UNIQUE_VALIDATION) &&
                  "同一アカウント名の管理者が存在します。"}
              </Text>
            </>
          )}
          <AdminForm submit={submit} backToTable={backToTable} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            管理者を登録する
          </Button>
          <Stack spacing={3} mb={3}>
            <Alert status="warning">
              <AlertIcon />
              店舗ごとに管理アプリ・集計アプリにログインするための管理者を登録します。
            </Alert>
          </Stack>
          <MainTable
            data={(data?.adminsByStoreId ?? []).map(getAdminRecord)}
            columns={columns}
            handleClick={handleClick}
          />
        </>
      )}
    </>
  );
};
