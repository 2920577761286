import { Box } from "@chakra-ui/react";
import React from "react";

export const MainCardContainer: React.FC = ({ children }) => (
  <Box
    as="section"
    className="main-card"
    bg="white"
    pt="8"
    pb="12"
    px={{ md: "8" }}
    rounded={{ md: "lg" }}
    shadow="base"
    // Selectの長いOptionの下の方が見えなくなるため外す
    // overflow="hidden"
  >
    {children}
  </Box>
);
