import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import {
  StoreTableResponse,
  useCreateStoreTableMutation,
  useGetStoreTablesByStoreIdQuery,
} from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { MainTable } from "components/molecules/main-table";
import { StoreTableForm } from "components/organisms/store-table-form";
import { StoreContext } from "contexts/store.context";
import { StoreTableSubmit } from "interfaces";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

const columns = [
  {
    Header: "テーブル",
    accessor: "name",
  },
  {
    Header: "説明",
    accessor: "description",
  },
];

export const StoreTableLayout: React.VFC = () => {
  const { push } = useHistory();
  const [isForm, setForm] = useState<boolean>(false);
  const { storeId } = useContext(StoreContext);
  const { loading, error, data, refetch } = useGetStoreTablesByStoreIdQuery({
    variables: { storeId },
    fetchPolicy: "cache-and-network",
  });
  const [createStoreTable] = useCreateStoreTableMutation();

  const backToTable = (): void => {
    setForm(false);
    void refetch();
  };

  const handleClick = (row: StoreTableResponse): void =>
    push(`${routeConstants.storeTables}/${row.id}?storeId=${storeId}`);

  const submit = async (input: StoreTableSubmit): Promise<void> => {
    await createStoreTable({ variables: { storeId, ...input } });
  };

  if (loading) return null;
  if (error) return <ErrorLayout />;

  return (
    <>
      {isForm ? (
        <Box>
          <ArrowBackIcon mb="6" onClick={backToTable} className="cursor-pointer" />
          <StoreTableForm backToTable={backToTable} submit={submit} />
        </Box>
      ) : (
        <>
          <Button colorScheme="blue" mb={6} onClick={() => setForm(true)}>
            テーブルを登録する
          </Button>
          <MainTable
            data={data?.storeTablesByStoreId ?? []}
            columns={columns}
            handleClick={handleClick}
          />
        </>
      )}
    </>
  );
};
