// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-key */
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import {
  chakra,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { isDefaultBasicItem } from "components/layouts/tabs/item-layout";
import React from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";

interface DataTableProps<T> {
  data: T[];
  columns: Array<Column>;
  handleClick?: (row: any) => void;
}

const DataTable = <T,>({ data, columns, handleClick }: DataTableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data }, useSortBy, usePagination);

  const isShowPagination = React.useMemo(() => {
    return pageOptions.length >= 2;
  }, [pageOptions]);

  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <Tr
                {...row.getRowProps()}
                // デフォルト基本料金は壊されたくないので編集不可に
                {...(!isDefaultBasicItem(row)
                  ? { onClick: () => handleClick && handleClick(row.original) }
                  : {})}
              >
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                    // 基本料金カテゴリの商品以外で行クリックが設定されていてチェックボックスのセルではない時
                    {...(!isDefaultBasicItem(row) && handleClick && cell.column.id !== "checkBox"
                      ? { className: "cursor-pointer" }
                      : {})}
                    // チェックボックス入切のクリックが行クリックまで伝播するのを止める
                    {...(cell.column.id === "checkBox"
                      ? { onClick: (e) => e.stopPropagation() }
                      : {})}
                  >
                    {cell.render("Cell")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {isShowPagination && (
        <Flex justifyContent="space-between" mt={8} alignItems="center">
          <Flex>
            <Tooltip label="First Page">
              <IconButton
                aria-label="最初へ"
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label="Previous Page">
              <IconButton
                aria-label="前へ"
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            <Text flexShrink={0} mr={8}>
              {" "}
              <Text fontWeight="bold" as="span" style={{ marginRight: "2px" }}>
                {pageIndex + 1}
              </Text>
              /
              <Text fontWeight="bold" as="span" style={{ marginLeft: "2px" }}>
                {pageOptions.length}ページ
              </Text>
            </Text>
            <Text flexShrink={0}>ページ:</Text>{" "}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={pageOptions.length}
              onChange={(value) => {
                const page = value ? Number(value) - 1 : 0;
                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  表示 {pageSize} 件
                </option>
              ))}
            </Select>
          </Flex>

          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                aria-label="次へ"
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label="Last Page">
              <IconButton
                aria-label="最後へ"
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </>
  );
};

interface MainTableProps<T> {
  data: T[];
  columns: Array<Column>;
  handleClick?: (row: any) => void;
}

export const MainTable = <T,>({ data, columns, handleClick }: MainTableProps<T>) => {
  if (data.length === 0) {
    return <Text>データがありません。</Text>;
  }

  return <DataTable data={data} columns={columns} handleClick={handleClick} />;
};
