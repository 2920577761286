import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
import { getIdForDisplay } from "@kg-pos/common";
import { CustomerResponse } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { CustomerMergeSubmit } from "interfaces";
import React from "react";
import { useForm } from "react-hook-form";

// 他項目との比較によるバリデーションは確認メールアドレスの実装を参考に
// see: https://hirakublog.com/react-hook-form_re-enter-validation/

type CustomerMergeFormProps = {
  submit: (input: CustomerMergeSubmit) => Promise<void>;
  backToTable: () => void;
  data: CustomerResponse[];
};

export const CustomerMergeForm: React.VFC<CustomerMergeFormProps> = ({
  backToTable,
  submit,
  data,
}) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    trigger,
  } = useForm();

  const onSubmit = async (input: CustomerMergeSubmit): Promise<void> => {
    const { parentId, childId } = input;
    await submit({ parentId, childId });
    const parentName = data.find((customer) => customer.id === parentId)?.name ?? "";
    const childName = data.find((customer) => customer.id === childId)?.name ?? "";
    toast({
      title: `${getIdForDisplay(parentId)} ${parentName}に${getIdForDisplay(
        childId
      )} ${childName}をマージしました。`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    backToTable();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="parentId" isInvalid={!!errors.parentId} mt={6} maxW="500px">
        <FormLabel>
          残す顧客
          <RequireField />
        </FormLabel>
        <Select
          placeholder="残す顧客を選択してください。"
          {...register("parentId", {
            required: "残す顧客を選択してください。",
            onBlur: () => {
              if (getValues("childId")) {
                void trigger("childId");
              }
            },
          })}
        >
          {data.map((customer, key) => (
            <option key={key} value={customer.id}>
              {getIdForDisplay(customer.id)} {customer.name} (担当: {customer.hostName})
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.parentId?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="childId" isInvalid={!!errors.childId} mt={6} maxW="500px">
        <FormLabel>
          マージされる顧客
          <RequireField />
        </FormLabel>
        <Select
          placeholder="マージされる顧客を選択してください。"
          {...register("childId", {
            required: "マージされる顧客を選択してください。",
            validate: (value) => {
              const parentId = getValues("parentId");
              const childId = value;
              if (parentId === childId) {
                return "同じ顧客はマージできません。";
              }

              const parentHostId = data.find((customer) => customer.id === parentId)?.hostId;
              const childHostId = data.find((customer) => customer.id === childId)?.hostId;
              if (!parentHostId || !childHostId || parentHostId !== childHostId) {
                return "担当の異なる顧客はマージできません。";
              }

              return true;
            },
          })}
        >
          {data.map((customer, key) => (
            <option key={key} value={customer.id}>
              {getIdForDisplay(customer.id)} {customer.name} (担当: {customer.hostName})
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.childId?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        マージする
      </Button>
    </form>
  );
};
