import { useDeleteStoreTableMutation, useGetStoreTableByIdQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const StoreTablePage: React.VFC = () => {
  const id = useParams();
  useTitle("テーブル詳細");
  const { loading, error, data } = useGetStoreTableByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteStoreTable] = useDeleteStoreTableMutation();

  const storeTable = data?.storeTable;

  if (loading) return <LoadingLayout />;
  if (error || !storeTable) return <ErrorLayout />;

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteStoreTable({ variables: { id } })).data?.deleteStoreTable;
  };

  return (
    <>
      <PageTitle
        title="テーブル詳細"
        breadCrumbItems={[
          { label: "テーブル", path: `${routeConstants.stores}/${storeTable.storeId}` },
          {
            label: "テーブル詳細",
            path: `${routeConstants.storeTables}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名前", value: storeTable.name },
          { label: "説明", value: storeTable.description },
        ]}
        editPath={`${routeConstants.storeTables}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${storeTable.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
