import { Box } from "@chakra-ui/react";
import { useGetHostQuery, useUpdateHostMutation } from "__generated__/graphql";
import { MainCardContainer } from "components/containers/main-card";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { HostForm } from "components/organisms/host-form";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import { HostSubmit } from "interfaces";
import React from "react";
import { useHistory } from "react-router-dom";
import { routeConstants } from "routes";

export const HostEditPage: React.VFC = () => {
  const history = useHistory();
  const id = useParams();
  useTitle("ホスト編集");
  const { loading, error, data } = useGetHostQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [updateHost] = useUpdateHostMutation();

  const host = data?.host;

  if (loading) return <LoadingLayout />;
  if (error || !host) return <ErrorLayout />;

  const submit = async (input: HostSubmit): Promise<void> => {
    await updateHost({ variables: { id, ...input } });
  };

  const backToTable = (): void => void history.goBack();

  return (
    <>
      <PageTitle
        title="ホスト編集"
        breadCrumbItems={[
          { label: "ホスト", path: `${routeConstants.stores}/${host.storeId}` },
          {
            label: "ホスト詳細",
            path: `${routeConstants.hosts}/${id}`,
          },
          {
            label: "ホスト編集",
            path: `${routeConstants.hosts}/edit/${id}`,
            active: true,
          },
        ]}
      />

      <MainCardContainer>
        <Box>
          <HostForm
            backToTable={backToTable}
            submit={submit}
            data={host}
            storeId={host.storeId ?? ""}
          />
        </Box>
      </MainCardContainer>
    </>
  );
};
