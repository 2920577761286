import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { AdminTypeList, getAdminType } from "@kg-pos/common";
import { AdminResponse, AdminType } from "__generated__/graphql";
import { RequireField } from "components/atoms/require-field";
import { AdminSubmit } from "interfaces";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";

type AdminFormProps = {
  submit: ({ account, password, type }: AdminSubmit) => Promise<void>;
  backToTable: () => void;
  data?: AdminResponse;
};

export const AdminForm: React.VFC<AdminFormProps> = ({ submit, backToTable, data }) => {
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      account: data?.account,
      type: data ? getAdminType(data) : AdminType.ADMIN,
      password: "",
    },
  });

  const isEdit = useMemo((): boolean => {
    return !!data;
  }, [data]);

  const onSubmit = async ({ account, password, type }: AdminSubmit): Promise<void> => {
    try {
      await submit({ account, password, type });
      toast({
        title: isEdit ? `${account}を編集しました。` : `${account}を新規作成しました。`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      backToTable();
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("cannot create admin", error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="account" isInvalid={!!errors.account}>
        <FormLabel>
          管理者アカウント
          <RequireField />
        </FormLabel>
        <Input
          id="name"
          type="text"
          placeholder="管理者アカウントを入力してください。"
          maxW="400px"
          disabled={isEdit}
          {...register("account", {
            required: "管理者アカウントが必要です。",
            maxLength: { value: 30, message: "30文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.account?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="type" isInvalid={!!errors.type} mt={6}>
        <FormLabel htmlFor="type">
          管理者タイプ
          <RequireField />
        </FormLabel>
        <Select
          placeholder="管理者タイプを選択してください。"
          {...register("type", {
            required: "管理者タイプを選択してください。",
          })}
          maxW="400px"
          disabled={isEdit}
        >
          <option value={AdminType.ADMIN}>{AdminTypeList[AdminType.ADMIN]}</option>
          <option value={AdminType.COLLECTOR}>{AdminTypeList[AdminType.COLLECTOR]}</option>
        </Select>
        <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="password" isInvalid={!!errors.password} mt={6}>
        <FormLabel>
          管理者パスワード
          <RequireField />
        </FormLabel>
        <Input
          id="password"
          type="password"
          placeholder="管理者パスワードを入力してください。"
          maxW="400px"
          {...register("password", {
            required: "管理者パスワードが必要です。",
            minLength: { value: 8, message: "8文字以上で入力してください。" },
            maxLength: { value: 100, message: "100文字以下で入力してください。" },
          })}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <Button mt={8} colorScheme="blue" isLoading={isSubmitting} type="submit">
        {isEdit ? "編集する" : "登録する"}
      </Button>
    </form>
  );
};
