import { useDeleteHostMutation, useGetHostQuery } from "__generated__/graphql";
import { getHostRecord } from "common/object/host";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const HostPage: React.VFC = () => {
  const id = useParams();
  useTitle("ホスト詳細");
  const { loading, error, data } = useGetHostQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteHost] = useDeleteHostMutation();
  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteHost({ variables: { id } })).data?.deleteHost;
  };

  const host = getHostRecord(data?.host);

  if (loading) return <LoadingLayout />;
  if (error || !host || !host.store) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="ホスト詳細"
        breadCrumbItems={[
          { label: "ホスト", path: `${routeConstants.stores}/${host.storeId}` },
          {
            label: "ホスト詳細",
            path: `${routeConstants.hosts}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "名前", value: host.name },
          { label: "読み", value: host.yomi },
          { label: "店舗", value: host.store.name },
          { label: "状態", value: host.status },
          { label: "ナンバー対象者", value: host.hostIsNumber },
          { label: "入店日", value: host.startDate },
          { label: "役職", value: host.title },
          { label: "表示優先度", value: host.sortNo },
        ]}
        editPath={`${routeConstants.hosts}/edit/${id}`}
        deletePath={`${routeConstants.stores}/${host.storeId}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
