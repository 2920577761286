import { Box, Button, Text } from "@chakra-ui/react";
import { MainCardContainer } from "components/containers/main-card";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { useHistory } from "react-router";

export const NotFound: React.VFC = () => {
  const { push } = useHistory();
  useTitle("ページが見つかりません");

  return (
    <Box mt="8">
      <MainCardContainer>
        <Box>
          <Text>ページが見つかりません。</Text>
        </Box>
        <Button colorScheme="blue" mt="10" onClick={() => push("/")}>
          店舗に戻る
        </Button>
      </MainCardContainer>
    </Box>
  );
};
