import { useDeleteExpenseItemMutation, useGetExpenseItemQuery } from "__generated__/graphql";
import { ErrorLayout } from "components/layouts/error";
import { LoadingLayout } from "components/layouts/loading";
import { SingleRead } from "components/molecules/single-read";
import { PageTitle } from "components/organisms/page-title";
import { useParams } from "hooks/useParams";
import { useTitle } from "hooks/useTitle";
import React from "react";
import { routeConstants } from "routes";

export const ExpensePage: React.VFC = () => {
  const id = useParams();
  useTitle("経費詳細");
  const { loading, error, data } = useGetExpenseItemQuery({
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });
  const [deleteItem] = useDeleteExpenseItemMutation();

  const handleDelete = async (): Promise<boolean> => {
    return !!(await deleteItem({ variables: { id } })).data?.deleteExpenseItem;
  };

  const expense = data?.expenseItem;

  if (loading) return <LoadingLayout />;
  if (error || !expense) return <ErrorLayout />;

  return (
    <>
      <PageTitle
        title="経費詳細"
        breadCrumbItems={[
          { label: "経費", path: routeConstants.expenses },
          {
            label: "経費詳細",
            path: `${routeConstants.expenses}/${id}`,
            active: true,
          },
        ]}
      />

      <SingleRead
        data={[
          { label: "項目名", value: expense.name },
          { label: "経費例", value: expense.examples || "" },
          { label: "表示優先度", value: expense.sortNo },
        ]}
        editPath={`${routeConstants.expenses}/edit/${id}`}
        deletePath={`${routeConstants.expenses}`}
        handleDelete={handleDelete}
      />
    </>
  );
};
